* {
  margin: 0;
  padding: 0;
}
html,
body {
  margin: 0;

  padding: 0;
}
body {
  position: relative;
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
div,
ul,
li,
button {
  margin: 0;
  padding: 0;
  text-decoration: none;
  border: none;
  background-color: transparent;
  list-style: none;
}

/* main-header */

.main-container {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url("compomnents/images/Group\ 1031.png");
  background-size: 150% 100%;
  background-position-x: 0%;
  animation: bgImage 50s infinite linear;
  z-index: 0;
  /* background-color: red; */
}
@keyframes bgImage {
  0% {
    background-position-x: 0%;
  }
  50% {
    background-position-x: 150%;
  }
  100% {
    background-position-x: 0%;
  }
}
.mo-deisplay {
  display: none;
}
.main-header {
  width: 100%;
  height: 36vh;
  max-height: 450px;
  min-height: 250px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  /* animation: changBGI 20s infinite linear; */
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url("./compomnents/images/Banner\ -\ No\ Logo.jpg");
  background-size: 100% 100%;
}
.main-header::after {
  content: "";
  width: 100%;
  height: 36vh;
  max-height: 450px;
  min-height: 250px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: 280px 250px;
  background-position: center;
  background-image: url("./compomnents/images/Logo\ for\ Website\ Banner.png");
  top: 0;
  left: 0;
  pointer-events: none;
  right: 0;
  z-index: -1;
}
/* @keyframes changBGI {
    0% {
      background-position: center 0%;
    }
    100% {
      background-position: center 100%;
    }
  } */
.nav-bar-container {
  position: absolute;
  top: 0;
  width: 27%;
  max-width: 477px;
  min-width: 280px;
  border-bottom: 2px solid #fdfdff;
  left: 50%;
  transform: translateX(-50%);
}

.nav-bar {
  display: flex;
  padding: 25px 15px 15px 15px;
  justify-content: space-between;
  align-items: center;
}
.nav-bar li button {
  font-size: 16px;
  color: #fdfdff;
  opacity: 0.5;
  cursor: pointer;
  transition: all 1s;
}
.nav-bar li:hover {
  opacity: 1;
  transform: scale(1.1);
}

/* body */

.main-body {
  width: 100%;
  height: calc(64vh - 75px);
  position: absolute;
  background-color: transparent;
  top: 36vh;
  left: 0;
  right: 0;
  overflow: hidden;
}
.we-make-the-hit {
  text-align: center;
  margin: 5vh auto 0 auto;
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  position: relative;
}
.we-make-the-hit h1 {
  font-size: 20px;
  font-family: "Open Sans", sans-serif !important;
  word-break: keep-all;
  font-weight: 600;
}
.we-make-the-hit::after {
  content: "";
  width: 55px;
  height: 1px;
  background-color: black;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.we-make-the-hit::before {
  content: "";
  width: 55px;
  height: 1px;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
.nav-bar-mobile {
  width: 90%;
  z-index: 3;
  position: absolute;
  bottom: 20px;
  border-bottom: 1px black solid;
  padding: 5px;
  display: none;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}
.nav-bar-mobile svg {
  margin: 5px 10px;
  width: 22px;
  height: 22px;
}
.second-icon {
  fill: white;
}

/* about-home-contact-us */

.about-home-contact-us {
  width: 100%;
  height: calc(100vh - 36vh - 75px - 36px - 5vh);
  position: relative;
}
#home {
  display: block;
  transition: top 0.8s, opacity 2s;
  opacity: 0;
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translateX(-50%);
}
.home-plus {
  opacity: 1 !important;
  top: 0 !important;
  transition: top 0.8s, opacity 2s;
}
#contact-us {
  position: absolute;
  left: 50%;

  transform: translateX(-50%);
  z-index: 2;
  width: 100%;
  top: 150%;
  opacity: 0;
  transition: top 0.8s, opacity 2s;
  display: flex;
  flex-direction: column;
}
.contact-us-plus {
  opacity: 1 !important;
  transition: top 0.8s, opacity 2s;
  top: 25px !important;
}
.form-data {
  display: flex;
  flex-direction: column;
  width: 70%;
  min-width: 230px;
  margin: 0 auto;
  max-width: 400px;
  background-color: #f1f6fb;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 3px 6px 2px #dfdfdf;
}
#name,
#email {
  height: 20px;
  border: 0px;
  border-bottom: 1px black solid;
  width: 90%;
  margin: 20px auto 10px auto;
  background-color: transparent;
}
#email {
  margin: 10px auto 45px auto !important;
}
#message {
  height: 20px;
  border: 0px;
  border-bottom: 1px black solid;
  width: 90%;
  margin: 20px auto 10px auto;
  background-color: transparent;
}
#button {
  height: 20px;
  border: 0px;
  width: 90%;
  margin: 10px auto 0px auto;
  background-color: #2d90c9;
  padding: 15px;
  border-radius: 7px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
input:focus-visible {
  outline: 0px;
}
input[type="text" i] {
  padding: 0px !important;
}
.contact-us-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 220px;
  margin: 0 auto;
  max-width: 350px;
  padding: 25px;
  width: 100%;
}
.contact-us-info div {
  width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  margin: 5px;
  padding: 5px;
}

/* about-us */

#about-us {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 90%;
  text-align: center;
  max-width: 850px;
  min-width: 250px;
  display: flex;
  color: #2065a0;
  line-height: 30px;
  padding: 10px;
  flex-direction: column;
  font-size: 18px;
  transition: top 0.8s, opacity 2s;
  opacity: 0;
  top: 150%;
}
.about-us-plus {
  /* display: flex !important; */
  opacity: 1 !important;
  top: 40% !important;
  transition: top 0.8s, opacity 2s;
}
.about-us h2 {
  margin: 10px 0 20px 0;
}

/* 
  foooter */

.foooter {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 25px;
  width: 100%;
  height: 50px;

  left: 0;
}
.app-download {
  padding: 0px 25px;
  display: flex;
}
.app-download :nth-child(2) {
  padding-left: 30px;
}
.app-download svg {
  cursor: pointer;
}

.socail-media {
  width: 220px;
  margin-left: 25px;
}
.socail-media ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.socail-media ul li a {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.socail-media li {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #43484c;
  padding: 7px;
  transition: all 0.5s;
}
.orka {
  display: flex;
  flex-direction: column;
}
.orka span {
  font-weight: 200 !important;
}
.orka p {
  font-size: 9px;
  margin-bottom: 0px;
  text-align: center;
  font-weight: 100;
}
.orka h1 {
  font-size: 10px;
  color: black;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 800;
}
.number-1:hover {
  background-color: #3b5998;
  transform: scale(1.5);
}
.number-2:hover {
  background-color: #1da1f2;
  transform: scale(1.5);
}
.number-4:hover {
  background-color: #833ab4;
  transform: scale(1.5);
}
.number-3:hover {
  background-color: #ff0000;
  transform: scale(1.5);
}
.number-5:hover {
  background-color: rgba(127, 93, 250, 0.904);
  transform: scale(1.5);
}
.number-6:hover {
  background-color: #25d366;
  transform: scale(1.5);
}
.first-icon {
  fill: black;
}
#first-button {
  opacity: 1;
  transform: scale(1.2);
  font-weight: 700;
}
/* slider */

#slider {
  position: relative;
  width: 90%;
  height: 320px;
  margin: 25px auto;
  font-family: "Helvetica Neue", sans-serif;
  perspective: 1200px;
  transform-style: preserve-3d;
}

#slider label,
#slider label img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  border-radius: 15px !important;
  height: 100%;
  left: 0;
  top: 0;
  color: white;
  font-size: 70px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 600ms ease;
}
#s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3,
#s4:checked ~ #slide4,
#s5:checked ~ #slide5,
#s6:checked ~ #slide6,
#s7:checked ~ #slide7,
#s8:checked ~ #slide8,
#s9:checked ~ #slide9,
#s10:checked ~ #slide10,
#s11:checked ~ #slide11,
#s12:checked ~ #slide12,
#s13:checked ~ #slide13,
#s14:checked ~ #slide14,
#s15:checked ~ #slide15 {
  box-shadow: 0 13px 26px rgba(0, 0, 0, 0.3), 0 12px 6px rgba(0, 0, 0, 0.2);
  /* transfor m: translate3d(0%, 0, 0px); */
}
input[type="radio"] {
  position: relative;
  display: none;
  top: 108%;
  left: 50%;
  width: 18px;
  height: 18px;
  margin: 0 15px 0 0;
  opacity: 0.4;
  transform: translateX(-83px);
  cursor: pointer;
}

input[type="radio"]:nth-child(5) {
  margin-right: 0px;
}

input[type="radio"]:checked {
  opacity: 1;
}
/* .mobile-deisplay{
    display: none ;
  } */
.fas,
.fa {
  padding: 0 20px;
}
.bxl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  z-index: 2;
  color: white;
  font-size: 28px;
}
.bxr {
  color: white;
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  right: 20px;
  font-size: 28px;
}
.load {
  transition: all 1.5s;
  pointer-events: none;
}
.load img {
  width: 75px;
  height: 75px;
  margin-bottom: 25px;
}
.spans {
  width: auto;
  height: auto;
  display: flex;
  flex: row;
}
.spans div {
  width: 15px;
  height: 15px;
  margin: 35px 15px 15px 15px;
  padding: 10px;
  border-radius: 50%;
  background-color: black;
}
@keyframes glow {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.one {
  animation: glow infinite linear 1s 0.3s;
}
.two {
  animation: glow infinite linear 1s 0.6s;
}
.three {
  animation: glow infinite linear 1s 0.9s;
}
.audio-player {
  z-index: 2;
}

.audio-player button {
  transition: all 0.5s;
}
.audio-player button:hover {
  transform: scale(1.4);
}
.app-download a {
  transition: all 0.7s;
}
.app-download a:hover {
  transform: scale(1.2);
}
