body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.password-eye {
  top: 50% !important;
  transform: translateY(-50%) !important;
  right: 5% !important;
}
.bg-change {
  background-image: url("./assets/png/background.png");
  background-position: 0% 100%;
  background-size: 200% 100%;
  animation: bgbg 40s linear infinite;
}
@keyframes bgbg {
  0% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 100% 100%;
  }
}
