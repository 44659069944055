@media (max-width: 480px) {
  .main-container {
    animation: bgImage 20s infinite linear;
    min-height: 650px;
  }

  .mo-deisplay {
    display: flex;
    top: -60px;
    height: fit-content;
  }
  .mo-deisplay button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-bar-container {
    display: none;
    animation: bgImage 25s infinite linear;
  }
  .fas,
  .fa {
    color: #000;
    padding: 0 5px;
  }
  .main-header {
    max-height: 200px;
    height: 25vh;
    max-height: fit-content;
    min-height: fit-content;
    min-height: 180px;
    background-size: auto 100%;

    /* background-size: 120% 100%; */
    /* background-size: 100% 100%; */
    background-image: url("compomnents/images/Banner.jpg");
    background-position: top;
    /* animation: changBGI 10s infinite linear; */
  }
  .main-body {
    top: 25vh;
    min-height: 450px;
    height: calc(100vh - 200px - 75px);
  }
  .about-home-contact-us {
    height: calc(100vh - 25vh - 75px - 36px - 5vh) !important;
  }
  .pc-deisplay {
    min-width: 120px !important;
    top: 80vh;
    display: none !important;
    width: 60% !important;
  }
  .pc-deisplay i,
  .pc-deisplay img {
    font-size: 25px;
    height: 25px;
    width: 25px;
  }
  .audio-player-progressbar {
    display: none;
  }
  .audio-player-info {
    display: none !important;
  }
  .app-download {
    display: none;
  }
  .socail-media {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
  .socail-media p {
    margin: 0px;
    font-size: 8px;
  }
  .socail-media li {
    width: 25px;
    margin: 0px 4px;
    height: 25px;
    padding: 5px;
  }
  .nav-bar-mobile {
    display: flex;
    bottom: 70px;
  }

  .form-data {
    min-width: 280px;
    width: 100%;
    justify-content: space-between;
    max-width: 320px;
    /* height: 30vh; */
    padding: 15px;
  }
  .contact-us {
    top: 20px;
    flex-direction: column-reverse !important;
  }
  .contact-us-info {
    padding: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
  }
  .loaction {
    width: 100% !important;
  }
  .contact-us-info div {
    gap: 5px;
    padding: 0px;
    width: 46%;
    font-size: 10px;
  }
  .about-us {
    font-size: 13px !important;
  }
  .orka p {
    margin-bottom: 10px;
  }
  .orka h1 {
    margin-bottom: 0px;
    font-size: 10px;
  }
  #slider {
    height: 220px;
    width: 55%;
  }
  .foooter {
    bottom: 10px; /*//10px// */
    width: 90%;
    height: 50px;
    /* background-color: red; */
    left: 50%;
    transform: translateX(-50%);
  }
  .audio-player button {
    cursor: pointer;
    color: black !important;
    width: 25px;
    height: 25px;
  }
  .audio-player button svg {
    width: 100% !important;
    height: 100% !important;
  }
  .main-header::after {
    background-size: 25% 90%;
    display: none ;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .main-container {
    animation: bgImage 30s infinite linear;
  }
  .app-download svg {
    width: 80px !important;
    height: 25px !important;
  }
  .socail-media li {
    width: 28px;
    height: 28px;
    padding: 7px;
  }
  .socail-media {
    width: 180px;
  }
  .audio-player-progressbar {
    width: 50% !important;
  }
  /* #volume-control{
          left: -15.5vw !important;
      } */
  .audio-player {
    width: 80%;
  }
  
  .main-header::after {
    background-size: 210px 190px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .audio-player-progressbar {
    width: 55% !important;
  }
  
  .main-header::after {
    background-size: 230px 210px;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .audio-player-progressbar {
    width: 60% !important;
  }
  
  .main-header::after {
    background-size: 250px 230px;
  }
}
@media (min-width: 1201px) {
}
@media (max-height: 670px) {
  #slider {
    height: 220px;
    width: 65%;
  }
  .about-us-plus {
    top: 50% !important;
  }
  #about-us {
    line-height: 22px;
  }

  #e-mail {
    margin: 10px auto 0px auto !important;
  }
  #message {
    margin: 10px auto 10px auto;
  }
  #email {
    margin: 10px auto 10px auto !important;
  }
}
.audio-player-info {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  min-width: 25px;
  width: 100%;
  height: 30px;
}

.audio-player-progressbar {
  background-color: white;
  position: relative;
  width: 70%;

  height: 3px;
}

.audio-player-progressmeter {
  background-color: rgb(69, 69, 255);
  width: calc(100% - 20%);
  z-index: 2;
  /* position: absolute; */
  height: 3px;
}

.audio-player {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 10%;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 200px;
}
.audio-player button {
  cursor: pointer;
  color: #dfdfdf;
}
#volume-control {
  position: absolute;
  transform: rotate(90deg);
  left: -75px;
}
/* #button-minus{
    position: absolute;
    padding: 50px;
    z-index: -1;
    left: -90px;
  } */
